import React, { useState } from 'react';

const Card = (props) => {

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const [play, setPlay] = useState(true)

  const videoElement = document.querySelector('video'); // Get video elemet to play and pause from card

  const playVideo = async () => {
    if (videoElement) {
      await props.setState({ ...props.state, videoToPlay: props })
      if(!isSafari) {
        await videoElement.play()
      }
      if(isSafari) {
        videoElement.pause()
        props.setState({ ...props.state, isPlaying: false, videoToPlay: props })
      }
    };
  };

  const pauseVideo = () => {
    if (videoElement) {
      videoElement.pause()
    };
  };

  const playPauseFromCard = () => {
    play ? playVideo() : pauseVideo()
    setPlay(!play)
  }

  const toHHMMSS = (duration) => { // Format video duration
    let sec_num = duration;
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = Math.trunc(sec_num - (hours * 3600) - (minutes * 60));

    if (hours < 10) { hours = "0" + hours; };
    if (minutes < 10) { minutes = "0" + minutes; };
    if (seconds < 10) { seconds = "0" + seconds; };

    if (hours > 0) { return hours + ':' + minutes + ':' + seconds }
    else { return minutes + ':' + seconds };
  };

  return (
    <div className="columns card-container" onClick={() => playPauseFromCard()}>
      <div className="column is-4">
        <img src={props.thumbnail} alt="" width="400" height="400" />
      </div>
      <div className="column card-details">
        <p className="title is-6">{props.title}</p>
        <div>
          <span>{toHHMMSS(props.duration)}</span>
          {props.state.videoToPlay && props.state.videoToPlay.index === props.index && props.state.isPlaying &&
            <button className="play-archived-btn is-pulled-right" onClick={pauseVideo}>
              <i className="fa fa-pause fa-pause fa-xs" />
            </button>
          }
          {props.state.videoToPlay && props.state.videoToPlay.index === props.index && !props.state.isPlaying &&
            <button className="play-archived-btn is-pulled-right" onClick={playVideo}>
              <i className="fa fa-pause fa-play fa-xs" />
            </button>
          }
          {(!props.state.videoToPlay || props.state.videoToPlay.index !== props.index) &&
            <button className="play-archived-btn is-pulled-right" onClick={playVideo}>
              <i className="fa fa-play fa-play fa-xs" />
            </button>
          }
        </div>
      </div>
    </div>
  )
};

export default Card;