import React, { useEffect, useState } from 'react';
import {
  Chat,
  Channel,
  Window,
  MessageInput,
  MessageInputSmall,
  Thread } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import _ from 'lodash';

import CustomMessageLivestream from './customMessageLivestream';
import CustomMessageList from './customMessageList/customMessageList';

import { Link } from "react-router-dom";

import 'stream-chat-react/dist/css/index.css';

const chatClient = new StreamChat(process.env.REACT_APP_STREAM_LIVE_CHAT_ID);

const LiveChat = (props) => {
  const [channel, setChannel] = useState({})
  const [chatIsEmpty, setChatIsEmpty] = useState(false)
  const [isAnon, setIsAnon] = useState(false)
  const [error, setError] = useState(false)
  
  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

  useEffect(() => {
    // Should only run once
    async function init() { await initChat() };
    init()

    return () => {
      chatClient.disconnect() // disconnect stream user connection on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function initChat () {
    // Wait till we've checked auth fully
    try {
      if(!_.isEmpty(props.user)) {
        var uniqueIdFromEmail = props.user.email.replace(/[^a-zA-Z ]/g, "");
        await chatClient.setGuestUser(
          {
            id: uniqueIdFromEmail,
            name: props.user.name,
          }
        );
      }
      else {
        await chatClient.setAnonymousUser()
        setIsAnon(true)
      }
  
      const channelInst = await chatClient.channel('livestream', process.env.REACT_APP_STREAM_CHANNEL_ID, {
        name: process.env.REACT_APP_STREAM_CHANNEL_NAME
      });
  
      setChatIsEmpty(_.isEmpty((await channelInst.watch()).messages))
      setChannel(channelInst)
    } catch(error) {
      console.log(error)
      setError(true)
    }
  }

  return (
    <React.Fragment>
      {chatClient && channel.id ?
      <Chat client={chatClient} theme={'livestream dark'}>
        <Channel channel={channel} Message={CustomMessageLivestream}>
          <Window hideOnThread>
              {!chatIsEmpty ? <CustomMessageList /> : 
                <section className="section"><p className="has-text-white is-size-7">Be the first!</p></section>
              }
            {isAnon ? <Link to="/">Must register to partake</Link> : <MessageInput maxNumberOfFiles={0} Input={MessageInputSmall} additionalTextareaProps={isMobile ? {} : {placeholder: "Type your message and press enter to send"}} focus /> }
          </Window>
          <Thread fullWidth />
        </Channel>
      </Chat>  : error ? 'Chat is unavailable for this session' : '...Loading'}
    </React.Fragment>
  );
}

export default LiveChat;
