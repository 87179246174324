import React, { useState, useEffect } from 'react';
import LiveChat from '../LiveChat';
import _ from 'lodash';
import ReactPlayer from 'react-player';

const Stream = (props) => {
  const [error, setError] = useState("");
  const [timer, setTimer] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

  const config = {
      file: {
        forceHLS: isSafari ? false : true
      }
    };

  const errorListener = function ( errorEvent ) {
    setError(errorEvent)
  };

  const playListener = function (playEvent) {
    clearTimeout(timer)
    setTimer({})
    setError("")
  };

  useEffect(() => { 
      if(window.ga) {// Update google analytics with user and current path
        window.ga('set', 'page', '/stream');
        window.ga('send', 'pageview');
      }
      if (process.env.NODE_ENV === 'production') {
        window.clevertap.event.push("View stream");  // Fire clevetap event when the user gets to /stream
      }
    })
  const liveChatElement = document.getElementsByClassName("live-questions");
  const expandChat = () => {
    isExpanded ? liveChatElement[0].style.height = "50%" : liveChatElement[0].style.height = "100%"
    setIsExpanded(!isExpanded)
  };

  return (
       <section data-testid="stream-page" className="stream-page">
        <div className="container-fluid">
          <div className="columns">
            <div className="column is-8">
              <div className="video">
                {!_.isEmpty(error) || _.isEmpty(process.env.REACT_APP_WOWZA_STREAM_URL) ?
                  <p className="unavailable has-text-centered">This stream is currently unavailable. <br /><button onClick={() => window.location.reload()}>Retry</button></p> :
                  <ReactPlayer
                    config={config}
                    onError={errorListener}
                    volume={0.8}
                    onReady={playListener}
                    width="100%"
                    height={isIOS || isAndroid? "100%" : isSafari ? "100%" : "-webkit-fill-available"}
                    url={process.env.REACT_APP_WOWZA_STREAM_URL}
                    controls
                    playing={isSafari ? false : true}
                    playsinline
                  />
                }
              </div>
            <div className="hidden-xs">
              <h1>{process.env.REACT_APP_STREAM_CHANNEL_NAME}</h1>
              <p>{process.env.REACT_APP_STREAM_DESCRIPTION}<br /><br />Powered by <a href="https://plankk.com" target="_blank" rel="noopener noreferrer">Plankk</a></p>
            </div>
          </div>
          <div className="column is-4 is-paddingless container--chat">
            <div className="live-questions">
              <div className="center-align container--header">
                <div className="hidden-xs">
                  <h2>Live Stream</h2>
                </div>
                <div className="visible-xs">
                  <h2>Live Stream
                    {isMobile && !isExpanded && 
                       <button className="btn expand-button is-pulled-right" onClick={expandChat}><i className="fas fa-chevron-up"></i></button>}
                    {isMobile && isExpanded && 
                       <button className="btn expand-button is-pulled-right" onClick={expandChat}><i className="fas fa-chevron-down"></i></button>}
                  </h2>
                </div>
              </div>
              <div className="chat-widget">
                {process.env.REACT_APP_STREAM_CHANNEL_ID ?
                  props.ready ? <LiveChat user={props.user} /> : '...loading top'
                  :
                  <div className="disabled-chat">
                    <p>Welcome to your LIVE workout party!</p>
                    <h3>Playback Issues</h3>
                    <ul>
                      <li>If the video doesn't start automatically, tap the play button to start the stream.</li>
                      <li>If the stream is buffering, just hit refresh. That should fix you right up!</li>
                      <li>If on your phone, tilt it to landscape mode to get a better view.</li>
                      <p></p>
                    </ul>
                    <h3>Watch Later</h3>
                    <p>Can’t make the whole class? We’ll email it to you tomorrow!</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stream;