import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import ReactPlayer from 'react-player';
import axios from 'axios';
import moment from 'moment'

import Card from '../card/Card';
import Logo from '../../assets/images/logo.png';

const Archive = () => {

  const [state, setState] = useState({
    error: "",
    timer: {},
    duration: 0,
    currentTime: 0,
    isPlaying: false,
    data: [],
    loadingAssets: "",
    videoToPlay: ""
  })

  const [listenersAdded, setListenersAdded] = useState(false)

  const playButtonRef = useRef();
  const videoElementRef = useRef();
  const fullScreenRef = useRef();
  const muteRef = useRef();
  const seekBarRef = useRef();

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const isIOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const isAndroid = /Android/i.test(navigator.userAgent)

  const errorListener = function (errorEvent) {
    setState({ ...state, error: errorEvent })
  };

  const playListener = async function (playEvent) {
    addControlsEventListeners()
    clearTimeout(state.timer);
    setState({ ...state, timer: {}, error: "" });
  };

  const toHHMMSS = (duration) => { // Format video duration 
    let sec_num = duration;
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = Math.trunc(sec_num - (hours * 3600) - (minutes * 60));

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }

    if (hours > 0) { return hours + ':' + minutes + ':' + seconds }
    else { return minutes + ':' + seconds }
  }

  const sortByUploadedDate = (a, b) => {
    if (moment(a.uploaded_at).isAfter(b.uploaded_at)) {
      return -1;
    }
    if (moment(a.uploaded_at).isBefore(b.uploaded_at)) {
      return 1;
    }
    return 0;
  }

  const getVideos = async () => {

    try {
      setState({ ...state, loadingAssets: true }) // Prevent unavailable videos view

      const token = process.env.REACT_APP_FRAME_IO_TOKEN
      const accountIdReq = await axios.get('https://api.frame.io/v2/me', { headers: { Authorization: `Bearer ${token}` } })
      const teamIdReq = await axios.get(`https://api.frame.io/v2/accounts/${accountIdReq.data.account_id}/teams`, { headers: { Authorization: `Bearer ${token}` } })
      const rootIdReq = await axios.get(`https://api.frame.io/v2/teams/${teamIdReq.data[0].id}/projects`, { headers: { Authorization: `Bearer ${token}` } })
      const rootId = rootIdReq.data.filter(project => {
        let rootId;
        let projectName = process.env.REACT_APP__FRAME_IO_PROJECT_NAME;
        if (project.name.replace(/\s+/g, '').toLowerCase() === projectName.replace(/\s+/g, '').toLowerCase()) {
          rootId = project
        }
        return rootId
      })
      const assetsList = await axios.get(`https://api.frame.io/v2/assets/${rootId[0].root_asset_id}/children`, { headers: { Authorization: `Bearer ${token}` } })
      let assets = [];

      if (assetsList.data.length > 0) {
        assetsList.data.sort(sortByUploadedDate).map(asset => { // Destructure asset object and get what we need
          const bestQualityVideo = asset.h264_1080_best ? asset.h264_1080_best :
            asset.h264_720 ? asset.h264_720 : asset.h264_540 ? asset.h264_540 : asset.h264_360
          if (bestQualityVideo) {
            return assets.push({
              id: asset.id,
              title: asset.name,
              videoLink: bestQualityVideo,
              thumbnail: asset.thumb,
              duration: asset.duration
            })
          }
          return []
        })

        await setState({
          ...state,
          videoToPlay: assets.length > 0 ? { ...assets[0], index: 0 } : '', // Load first video into video player
          data: assets,
          loadingAssets: false // Enable unavailable videos view
        })
      }
    } catch (error) {
      setState({ ...state, loadingAssets: false }); // Enable unavailable videos view
      console.log(state.error);
    };
  };

  const videosList = state.data.map((element, index) => { // Render video cards
    return (
      <Card
        key={element.id}
        index={index}
        duration={element.duration}
        videoLink={element.videoLink}
        thumbnail={element.thumbnail}
        title={element.title}
        state={state}
        setState={setState}
      />
    )
  });

  useEffect(() => {
    getVideos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //Add Custom Controls
  const handlePlayPause = () => {
    if (videoElementRef.current.getInternalPlayer().paused === true) {
      // Play the video
      videoElementRef.current.getInternalPlayer().play();
      // Update the button text to 'Pause'
      playButtonRef.current.childNodes[0].setAttribute('class', 'fa fa-pause fa-pause fa-xs');
    } else {
      // Pause the video
      videoElementRef.current.getInternalPlayer().pause();
      // Update the button text to 'Pause'
      playButtonRef.current.childNodes[0].setAttribute('class', 'fa fa-play fa-play fa-xs');
    }
  }
  const handleMute = () => {
    if (videoElementRef.current.getInternalPlayer().muted === false) {
      // Mute the video
      videoElementRef.current.getInternalPlayer().muted = true;

      // Update the button text
      muteRef.current.childNodes[0].setAttribute('class', 'fas fa-volume-mute');
    } else {
      // Unmute the video
      videoElementRef.current.getInternalPlayer().muted = false;

      // Update the button text
      muteRef.current.childNodes[0].setAttribute('class', 'fas fa-volume-up');
    }
  }
  const handleFullScreen = () => {
    if (videoElementRef.current.getInternalPlayer().requestFullscreen) {
      videoElementRef.current.getInternalPlayer().requestFullscreen();
    } else if (videoElementRef.current.getInternalPlayer().mozRequestFullScreen) {
      videoElementRef.current.getInternalPlayer().mozRequestFullScreen(); // Firefox
    } else if (videoElementRef.current.getInternalPlayer().webkitRequestFullscreen) {
      videoElementRef.current.getInternalPlayer().webkitRequestFullscreen(); // Chrome and Safari
    } else if (videoElementRef.current.getInternalPlayer().msRequestFullscreen) {
      videoElementRef.current.getInternalPlayer().msRequestFullscreen();
    } else if (videoElementRef.current.getInternalPlayer().webkitEnterFullScreen) {
      videoElementRef.current.getInternalPlayer().webkitEnterFullScreen()
    }
    fullScreenRef.current.childNodes[0].setAttribute('class', 'fas fa-arrows-alt');
  }
  const handleSeekBarChange = () => {
    // Calculate the new time
    let time = videoElementRef.current.getInternalPlayer().duration * (seekBarRef.current.value / 100);
    // Update the video time
    videoElementRef.current.getInternalPlayer().currentTime = time;
  }
  const handleSeekBarTimeupdate = () => {
    // Calculate the slider value
    let value = (100 / videoElementRef.current.getInternalPlayer().duration) * videoElementRef.current.getInternalPlayer().currentTime;
    // Update the slider value
    seekBarRef.current.value = value;
  }
  const handleSeek = (e) => {
    let percent = ((e.offsetX / e.srcElement.clientWidth));
    videoElementRef.current.getInternalPlayer().currentTime = percent * videoElementRef.current.getInternalPlayer().duration;
    seekBarRef.current.value = (100 / videoElementRef.current.getInternalPlayer().duration) * videoElementRef.current.getInternalPlayer().currentTime;
  }
  const handleSeekBarMouseDown = () => {
    videoElementRef.current.getInternalPlayer().pause();
  }
  const handleSeekBarMouseUp = () => {
    videoElementRef.current.getInternalPlayer().play();
  }

  const addControlsEventListeners = async () => {

    if (listenersAdded) {
      await seekBarRef.current.removeEventListener("change", handleSeekBarChange)
      await seekBarRef.current.removeEventListener("timeupdate", handleSeekBarTimeupdate)
      await seekBarRef.current.removeEventListener("click", handleSeek)
      await seekBarRef.current.removeEventListener("mouseDown", handleSeekBarMouseDown)
      await seekBarRef.current.removeEventListener("mouseUp", handleSeekBarMouseUp)
    }
    if (!listenersAdded) {
      // Event listener for the full-screen button
      fullScreenRef.current.addEventListener("click", handleFullScreen);

      // Event listener for the play/pause button
      playButtonRef.current.addEventListener("click", handlePlayPause);

      // Event listener for the mute button
      muteRef.current.addEventListener("click", handleMute);
    }
    setListenersAdded(true)

    // Event listener for the seek bar
    seekBarRef.current.addEventListener("change", handleSeekBarChange);

    // Update the seek bar as the video plays
    videoElementRef.current.getInternalPlayer().addEventListener("timeupdate", handleSeekBarTimeupdate);

    // Pause the video when the slider handle is being dragged
    seekBarRef.current.addEventListener("mousedown", handleSeekBarMouseDown);

    // Play the video when the slider handle is dropped
    seekBarRef.current.addEventListener("mouseup", handleSeekBarMouseUp);

    // Event listener for click to seek on progress bar
    seekBarRef.current.addEventListener("click", handleSeek);
  }

  const playVideo = async () => {
    await videoElementRef.current.getInternalPlayer().play()
  };

  const pauseVideo = () => {
    videoElementRef.current.getInternalPlayer().pause()
  };


  return (
    <div className="slide-archive is-vertical-center">
      <div className="container-fluid">
        <div>
          <div className="logo">
            <img src={Logo} alt="PRETTY MUSCLES by Erin Oprea" />
          </div>
        </div>
        <div className="columns main-archived">
          <div className="column is-one-third">
            <div className="title">LIVE STREAM</div>
            <div className="video-list">
              {videosList}
            </div>
          </div>
          <div className="column is-half archived-container">
            <div className="video">
              {!_.isEmpty(state.error) ?
                <div className="unavailable-container">
                  <p className="unavailable has-text-centered">This video is currently unavailable. <br /><button onClick={() => window.location.reload()}>Retry</button></p>
                </div>
                :
                state.data.length > 0 ? <>
                  <span className="video-timer">{toHHMMSS(state.currentTime)}</span>
                  <ReactPlayer
                    ref={videoElementRef}
                    key={state.videoToPlay.index}
                    onError={errorListener}
                    volume={0.8}
                    onReady={playListener}
                    onPlay={() => setState({ ...state, isPlaying: true })}
                    onPause={() => setState({ ...state, isPlaying: false })}
                    onProgress={(progress) => setState({ ...state, currentTime: Math.trunc((progress.playedSeconds)) })}
                    onDuration={(duration) => setState({ ...state, duration: duration })}
                    width="100%"
                    height={isIOS || isAndroid ? "100%" : isSafari ? "100%" : "-webkit-fill-available"}
                    url={state.videoToPlay.videoLink}
                    playing={isSafari ? false : true}
                    playsinline
                  />
                  <div id="video-controls">
                    {(!state.isPlaying) &&
                      <button className="player-play-archived-btn" id="play-pause" ref={playButtonRef} onClick={playVideo}>
                        <i className="fa fa-play fa-play fa-xs" />
                      </button>
                    }
                    {(state.isPlaying) &&
                      <button className="player-play-archived-btn" id="play-pause" ref={playButtonRef} onClick={pauseVideo}>
                        <i className="fa fa-pause fa-pause fa-xs" />
                      </button>
                    }
                    <input type="range" id="seek-bar" ref={seekBarRef} />
                    <div className="right-controls">
                      <button type="button" id="mute" ref={muteRef}><i className="fas fa-volume-up"></i></button>
                      <button type="button" id="full-screen" ref={fullScreenRef}><i className="fas fa-arrows-alt"></i></button>
                    </div>
                  </div>
                </> :
                  !state.loadingAssets ? <div className="unavailable-container">
                    <p className="unavailable has-text-centered">No available videos to play, stay tuned...</p>
                  </div> : null
              }
            </div>
            {state.data.length > 0 ? <div className="column card-details">
              <p className="title is-6">{state.videoToPlay.title}</p>
              <div>
                <span>{toHHMMSS(state.duration)}</span>
              </div>
            </div> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Archive;