import React, { useState, useEffect }  from 'react';
import Logo from '../../assets/images/logo.png';
import PlankkLogo from '../../assets/images/plankk.png';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

const Home = (props) => {
    const history = useHistory();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [accessCode, setAccessCode] = useState("")
    const [loading, setLoading] = useState(false)

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const clearForm = () => {
        setName("")
        setEmail("")
        setAccessCode("")
    }

    const handleSubmit = e => {
        setLoading(true)
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "live-stream-erin-qanda", name, email, accessCode})
        })
          .then(() => {
            // Success. Netlfy should have grabbed this
            localStorage.setItem('sl-auth', JSON.stringify({name, email, accessCode}))
            props.setUser({name, email, accessCode})
            history.push('/stream')
            clearForm()
            setLoading(false)
 
            window.clevertap.onUserLogin.push({ // Create new user in clevertap
              "Site": {
                "Name": name,
                "Identity": email ? email : null,
                "Email": email
              }
            })
          })
          .catch(error => {
              console.log('Error', error)
              setLoading(false)
          });
  
        e.preventDefault();
      };

    useEffect(() => { // Update google analytics with user and current path
      if(window.ga) {
        window.ga('set', 'page', '/');
        window.ga('send', 'pageview');
      }
    })

    return (
    <div data-testid="home-page" className="slide is-vertical-center">
        <div className="container-fluid">
            <div className="columns">
                <div className="column is-7 hidden-xs"></div>
                <div className="column is-5">
                    <div className="live-panel">
                    <div className="logo">
                        <img src={Logo} alt="PRETTY MUSCLES by Erin Oprea" />
                    </div>
                    <div className="panel-content">
                        <h1>Live Stream</h1>
                        {_.isEmpty(props.user) ?
                        <React.Fragment>
                            <p>Enter your name and email to join the live stream</p>
                            <form onSubmit={handleSubmit} name="live-stream-erin-qanda" action="/stream" method="post">
                                <input type="hidden" name="form-name" value="live-stream-erin-qanda" />
                                <input name="name" placeholder="Name*" type="text" value={name} onChange={e => setName(e.target.value)} required />
                                <input name="email" placeholder="Email*" type="text" value={email} onChange={e => setEmail(e.target.value)} required />
                                <input name="accessCode" placeholder="Access Code*" value={accessCode} onChange={e => setAccessCode(e.target.value)} type="text" required />
                                <button type="submit" className="btn">
                                    {loading ? <span className="icon">
                                        <i className="fas fa-spinner fa-pulse"></i>
                                    </span> : "Join The Party"}
                                </button>
                            </form>
                        </React.Fragment> :
                        <React.Fragment>
                            <p>You've already registered</p>
                            <button onClick={() => history.push('/stream')} className="btn">Join The Party</button>
                        </React.Fragment>}
                    </div>
                </div>
                <div className="powered-by">
                    <a href="https://plankk.com" title="Plankk" rel="noopener noreferrer" target="_blank">
                    <span>POWERED BY</span>
                    <img src={PlankkLogo} alt="Powered by Plankk" />
                    <span>Plankk.com</span>
                    </a>
                </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Home