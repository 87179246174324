import React, { useEffect, useState } from 'react';
import Home from './views/Home';
import Stream from './views/Stream';
import Archive from './views/Archive';

import {
  Switch,
  Route
} from "react-router-dom";

import { useLocation } from "react-router";

const App = (props) => {
  let location = useLocation();
  const [user, setUser] = useState({})
  const [ready, setReady] = useState(false)

  useEffect(() => {
    // Check auth
    let auth = localStorage.getItem("sl-auth")
    if(auth) {
      // Already auth. Grab and set
      console.log('Setting User')
      setUser(JSON.parse(auth))
    }

    setReady(true)
  }, [])

  return (
    <div className="App" style={{backgroundColor: location.pathname === '/stream' ? '#1C1C1C' : 'inherit'}}>
      <Switch>
        <Route exact path="/">
          <Home ready={ready} user={user} setUser={setUser}/>
        </Route>
        <Route path="/stream">
          {ready ? <Stream ready={ready} user={user} setUser={setUser}/> : null}
        </Route>
        <Route path="/archive">
          {ready ? <Archive ready={ready} user={user} setUser={setUser}/> : null}
        </Route>
      </Switch>
    </div>
  );
}

export default App;
